<script setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router/auto";
import useDamageReports from "../../../hooks/useDamageReports.js";
import { setPageTitle } from "@/utils/pageTitle";
import DamageReportsLayout from "@/layouts/DamageReportsLayout.vue";
import { useUserStore } from "@/stores/user";

const route = useRoute();

const { fetchDamage, damage, fetchDamagePhotos, damagePhotos, uploadDamagePhoto } = useDamageReports();
const userStore = useUserStore();

onMounted(async () => {
  await fetchDamage(route.params.id);
  await fetchDamagePhotos(route.params.id);
  setPageTitle(`Damage Report // ${damage.value.id} Photos`);
});

const uploadPhotos = async (e) => {
  const file = e.target.files[0];
  const formData = new FormData();
  formData.append("images[][file]", file);
  const res = await uploadDamagePhoto(route.params.id, formData);
  if (res) {
    await fetchDamagePhotos(route.params.id);
  }
}
</script>

<template>
  <DamageReportsLayout v-if="damage" :damage="damage" title="Photos">
    <v-card flat variant="text" class="rounded-0">
      <v-card-title class="bg-surface-light d-flex justify-space-between">
        <span>Damage Photos </span>
        <v-btn 
          v-if="userStore?.user?.permissions?.process_damage_reports" 
          flat 
          color="primary" 
          @click="$refs.file.click()"
          >
            + Add Photo
        </v-btn>
        <input v-show="false" type="file" ref="file" @change="uploadPhotos" />
      </v-card-title>

      <div class="d-flex pa-2 flex-wrap">
        <v-card
          v-for="img in damagePhotos" :key="img.id"
          class="ma-2"
          width="250"
          height="250"
          variant="outlined"
          link
          target="_blank"
          :href="img?.url"
        >
          <v-img 
            :src="img?.url"
          >
              <template v-slot:placeholder>
                  <div class="d-flex align-center justify-center fill-height">
                      <v-progress-circular
                          color="grey-lighten-4"
                          indeterminate
                      ></v-progress-circular>
                  </div>
              </template>
          </v-img>
        </v-card>
      </div>

    </v-card>
  </DamageReportsLayout>
</template>
